<template>
  <div class="contianer page-hd">
    <div class="weui-flex">
      <div class="weui-flex__item">
        <a class="weui-btn bg-orange" v-on:click="downloadClick()">دەتالدى تۇسىرۋ</a>
      </div>
      <div class="weui-flex__item">
        <div class="logobox" v-on:click="homeClick()">
          <span class="appname">باس بەت</span>
          <img class="logo" src="../assets/logo.jpg" />
        </div>
      </div>
    </div>

    <div class="titlebox v-center">
      <h1 class="page-hd-title">{{ title }}</h1>
    </div>

    <div class="v-center">
      <img :src="'https://app.burket.cn/upload/' + cover" class="cover v-center" />
    </div>

    <div class="v-center">
      <audio class="au" :src="musicurl" controls controlsList="nodownload" autoplay></audio>
    </div>


    <img src="../assets/banner.jpeg" class="img-max" style="border-radius: 10px;"/>
    <h4 style="text-align:justify">بۇركىت دەتالىن تۇسرىپ الۋان تۇرلى مازمۇنداردى تاماشالاپ كەرەمەت يقۋاتتاردى پايدالانڭىز</h4>




  </div>
</template>

<script>
import { getMusicDetail } from "../api";

export default {
  name: "App",
  data() {
    return {
      cover: "",
      title: "",
      musicurl: "",
    };
  },
  methods: {

    downloadClick: function () {

      alert("ۇيشىننڭ ىشنەن باسقا دەتالداردى تۇسرۋگە بولمايتىن سەبەپتى وڭ جاق ۇستندەگى ۇشن نۇكتەنى باسپ 在浏览器打开دەگەندى باسپ تۇسىرىڭىز", function () {
        // return alert('回调');
        return false;
      });

    },
    homeClick: function () {
      this.$router.push({
        path: "/",
      });

    },

  },
  created: function () {
    var that = this;

    const vid = this.$route.query.id;

    getMusicDetail({ id: vid }).then(function (ret) {
      console.log(ret.data);

      that.cover = ret.data.img;
      that.title = ret.data.name;
      that.musicurl = ret.data.url;

    });
  },
};
</script>

<style>
.logo {
  width: 48px;
  height: 48px;
}

.logobox {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: left;
}

.appname {
  margin-left: 8px;
  margin-top: 8px;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.cover {
  border-radius: 10px;
  margin-top: 20px;
  width: 200px;
  height: 200px;
}

.titlebox {
  margin-top: 50px;
  width: 100%;
}

.au {
  margin-top: 20px;
}

.weui-news-list {
  margin-top: 40px;
}
</style>