import request from './request'

// export function getAll(data) {
//   return request({
//     url: 'getAll',
//     method: 'get',
//     data
//   })
// }

export function getAll(param) {
  return request({
    method: 'POST',
    url: "api/ShiPin/getShiPinDetail",
    data: param
  })
}


export function getHome() {
  return request({
    method: 'POST',
    url: "api/Index/getHome",
  })
}

export function getMusicList(param) {
  return request({
    method: 'POST',
    url: "api/YinYue/getMusicList",
    data: param
  })
}


export function getNewsDetail(param) {
  return request({
    method: 'POST',
    url: "api/XinWen/getXinWenDetail",
    data: param
  })
}


export function getMusicDetail(param) {
  return request({
    method: 'POST',
    url: "api/YinYue/getMusicDetail",
    data: param
  })
}


export function getShopDetail(param) {
  return request({
    method: 'POST',
    url: "api/ShangCheng/getShopDetail",
    data: param
  })
}

export function getRedictVideoUrl(param) {
  return request({
    method: 'POST',
    url: "api/Index/getRedictWxv",
    data: param
  })
}




