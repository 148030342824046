<template>

    <div class="contianer page-hd">

        <div class="weui-flex">
            <div class="weui-flex__item">
                <a class="weui-btn bg-orange" v-on:click="downloadClick()">دەتالدى تۇسىرۋ</a>
            </div>
            <div class="weui-flex__item">
                <div class="logobox" v-on:click="homeClick()">
                    <span class="appname">باس بەت</span>
                    <img class="logo" src="../assets/logo.jpg" />
                </div>
            </div>
        </div>


        <div ref="reportHTML" v-html="htmlText" class="web-con"></div>


        <img src="../assets/banner.jpeg" class="img-max" style="border-radius: 10px;"/>
    <h4 style="text-align:justify">بۇركىت دەتالىن تۇسرىپ الۋان تۇرلى مازمۇنداردى تاماشالاپ كەرەمەت يقۋاتتاردى پايدالانڭىز</h4>





    </div>


</template>

<script>

import { getNewsDetail } from "../api";


export default {

    data() {
        return {
            htmlText: ""
        }
    },
    methods: {

        downloadClick: function () {

            alert("ۇيشىننڭ ىشنەن باسقا دەتالداردى تۇسرۋگە بولمايتىن سەبەپتى وڭ جاق ۇستندەگى ۇشن نۇكتەنى باسپ 在浏览器打开دەگەندى باسپ تۇسىرىڭىز", function () {
                // return alert('回调');
                return false;
            });

        },
        homeClick: function () {
            this.$router.push({
                path: "/",
            });

        },

    },
    created: function () {
        var that = this;

        const vid = this.$route.query.id;

        getNewsDetail({ id: vid }).then(function (ret) {
            console.log(ret.data);
            that.htmlText = ret.data.content;
        });
    },



}
</script>

<style>
.web-con {
    margin-top: 40px;
    text-align: justify;
}

.logobox {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: left;
}

.appname {
  margin-left: 8px;
  margin-top: 8px;
  font-size: 20px;
  font-weight: bold;
    color: black;

}
</style>