import Vue from 'vue';
import Router from 'vue-router';
import home_view from '@/view/Home';
import videoplayer_view from '@/view/VideoPlayer';
import musicplayer_view from '@/view/MusicPlayer';
import error_view from '@/view/Error';
import newsdetail_view from '@/view/NewsDetail';
import musicdetail_view from '@/view/MusicDetail';
import shopdetail_view from '@/view/ShopDetail';




Vue.use(Router);

const router = new Router({

    routes: [
        {
            path: '/',
            name: 'Home',
            component: home_view,
        },
        {
            path: '/VideoPlayer',
            name: 'VideoPlayer',
            component: videoplayer_view,
        },
        {
            path: '/MusicPlayer',
            name: 'MusicPlayer',
            component: musicplayer_view,
        },
        {
            path: '/Error',
            name: 'Error',
            component: error_view,
        },
        {
            path: '/NewsDetail',
            name: 'NewsDetail',
            component: newsdetail_view,
        },
        {
            path: '/MusicDetail',
            name: 'MusicDetail',
            component: musicdetail_view,
        },
        {
            path: '/ShopDetail',
            name: 'ShopDetail',
            component: shopdetail_view,
        },

    ]

});
 


router.beforeEach((to, from, next) => {


    //  从其他地方访问是否有这个地址
    if (isWeiXin()) {
        next()
    } else {

        if (to.path === '/error') {
            next()
          } else {
            next('/error')
          }

    }
});


function isWeiXin(){
    var ua = navigator.userAgent.toLowerCase();//获取判断⽤的对象
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //在微信中打开
    return true;
   } else {
    return false;
    }
    }


export default router;