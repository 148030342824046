<template>

    <div class="contianer page-hd">
        <div class="weui-flex">
            <div class="weui-flex__item">
                <a class="weui-btn bg-orange" v-on:click="downloadClick()">دەتالدى تۇسىرۋ</a>
            </div>
            <div class="weui-flex__item">
                <div class="logobox" v-on:click="homeClick()">
                    <span class="appname">باس بەت</span>
                    <img class="logo" src="../assets/logo.jpg" />
                </div>
            </div>
        </div>

        <div class="v-center">
            <img style="width:100%;height: auto;" :src="'https://app.burket.cn/upload/' + cover" class="cover v-center" />
        </div>

        <div class="page-hd">
            <h4 style="width:100%;text-align: start;">
                {{ title }}
            </h4>
            <p class="page-hd-desc" style="width:100%;text-align: start;">{{ remark }}</p>
        </div>

        <div class="weui-cell">
            <div style="position: relative;margin-right: 10px;">
                <img :src="dianpucover" style="width: 50px;display: block">
            </div>
            <div class="weui-cell__bd" style="margin-right: 10px;">
                <p>{{ dianpuname }}</p>
                <p style="font-size: 13px;color: #888888;">ۇنحات ءنومىر: {{ weixinnum }}</p>
            </div>
        </div>

        <a href="javascript:;" v-on:click="callClick()" class="weui-btn weui-btn_mini bg-green"><i
                class="icon icon-105"></i>قابارلاسۋ</a>
        <a href="javascript:;" v-on:click="copyClick()" style="margin-right: 20px;"
            class="weui-btn weui-btn_mini bg-blue"><i class="icon icon-14"></i>ۇنحات ءنومىرىن كوشىرۋ</a>

        <div class="page-hd">
            <h4 style="width:100%;text-align: start;"> قوسىمشا سۇرەتتەر </h4>
        </div>

        <ul style="list-style: none;">
            <li v-for="item in imagelist">
                <img style="width:100%;height:auto" :src="'https://app.burket.cn/upload/' + item"
                    class="cover v-center" />
            </li>
        </ul>


       <img src="../assets/banner.jpeg" class="img-max" style="border-radius: 10px;"/>
    <h4 style="text-align:justify">بۇركىت دەتالىن تۇسرىپ الۋان تۇرلى مازمۇنداردى تاماشالاپ كەرەمەت يقۋاتتاردى پايدالانڭىز</h4>




    </div>

</template>

<script>
import { getShopDetail } from "../api";

export default {
    name: "App",
    data() {
        return {
            cover: "",
            title: "",
            remark: "",
            dianpucover: "",
            dianpuname: "",
            weixinnum: "",
            phonenum: "",
            imagelist: [],
        };
    },
    methods: {

        downloadClick: function () {

            alert("ۇيشىننڭ ىشنەن باسقا دەتالداردى تۇسرۋگە بولمايتىن سەبەپتى وڭ جاق ۇستندەگى ۇشن نۇكتەنى باسپ 在浏览器打开دەگەندى باسپ تۇسىرىڭىز", function () {
                // return alert('回调');
                return false;
            });

        },
        homeClick: function () {
            this.$router.push({
                path: "/",
            });

        },
        callClick: function () {

            window.location.href = 'tel:' + this.phonenum;

        },
        copyClick: function () {

            this.$copyText(this.weixinnum).then(function (e) {
                alert('ءتىل تاقتاعا كوشىرلدى')
            }, function (e) {
                alert('ءساتسىسز بولدى')
            })


        },

    },
    created: function () {
        var that = this;

        const vid = this.$route.query.id;

        getShopDetail({ id: vid }).then(function (ret) {
            console.log(ret.data);

            that.cover = ret.data.shop.img;
            that.title = ret.data.shop.title;
            that.remark = ret.data.shop.remark;
            that.dianpucover = ret.data.dianpu.img;
            that.dianpuname = ret.data.dianpu.name;
            that.weixinnum = ret.data.dianpu.wxnum;
            that.phonenum = ret.data.dianpu.phone;
            that.imagelist = ret.data.shop.imglist.split('^');


            // that.musicurl = ret.data.url;

        });
    },
};
</script>


<style>
.logo {
    width: 48px;
    height: 48px;
}

.logobox {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    text-align: left;
}

.appname {
    margin-left: 8px;
    margin-top: 8px;
    font-size: 20px;
    font-weight: bold;
    color: black;
}

.titlebox {
    margin-top: 10px;
    width: 100%;
}

</style>