<template>
  <div class="contianer page-hd">
    <div class="weui-flex">
      <div class="weui-flex__item">
        <a class="weui-btn bg-orange" v-on:click="downloadClick()">دەتالدى تۇسىرۋ</a>
      </div>
      <div class="weui-flex__item">
        <div class="logobox">
          <span class="appname">Burket</span>
          <img class="logo" src="../assets/logo.jpg" />
        </div>
      </div>
    </div>

    <img src="../assets/banner.jpeg" class="img-max" style="border-radius: 10px;" />

    <div class="title">
      <h3 class="page-hd-title">ۇسنىلعان كينولار</h3>
    </div>
    <ul class="weui-mark">
      <li style="width:33%" v-for="(item, index) in dianyinglist" :key="index">
        <div id="videoimg" class="weui-mark-img">
          <img class="coverimg" :src="item.img" :key="index" v-on:click="dianyingClick(index)" />
        </div>
        <div class="weui-mark-meta v-center">
          <span class="name page-hd">{{ item.name }}</span>
        </div>
      </li>
    </ul>

    <div class="title">
      <h3 class="page-hd-title">ۇسنىلعان سەريالدار</h3>
    </div>
    <ul class="weui-mark">
      <li style="width:33%" v-for="(item, index) in dianshijulist" :key="index">
        <div id="videoimg" class="weui-mark-img">
          <img class="coverimg" :src="item.img" :key="index" v-on:click="dianshijuClick(index)" />
        </div>
        <div class="weui-mark-meta v-center">
          <span class="name page-hd" >{{ item.name }}</span>
        </div>
      </li>
    </ul>

    <div class="title">
      <h3 class="page-hd-title">ۇسنىلعان ءان جيناقتار</h3>
    </div>
    <ul class="weui-mark">
      <li style="width:33%" v-for="(item, index) in gedanlist" :key="index">
        <div class="weui-mark-img">
          <img class="coverimg"  v-if="item.img.indexOf('http') != -1" :src="item.img" :key="index" v-on:click="dianshijuClick(index)" />
          <img class="coverimg"  v-if="item.img.indexOf('http') == -1" :src="'https://app.burket.cn/upload/' + item.img" :key="index"
            v-on:click="gedanClick(index)" />
        </div>
        <span class="weui-mark-lb" style="bottom:5em">{{ item.playnum }}ىرەت قويلدى </span>
        <div class="weui-mark-meta v-center">
          <span class="name page-hd">{{ item.name }}</span>
        </div>
      </li>
    </ul>

    <div class="title">
      <h3 class="page-hd-title">جاڭا حابارلار</h3>
    </div>
    <ul class="weui-news-list">
      <li class="weui-news-item" v-for="(item, index) in xinwenlist" :key="index">
        <div class="weui-news-inner" v-on:click="xinwenClick(index)">
          <div class="weui-news-inners">
            <div class="weui-news-text">
              <div class="weui-news-title">
                {{ item.name }}
              </div>
            </div>
            <div class="weui-news-info">
              <div class="weui-news-infoitem">
                <img  src="../assets/newsplay.svg" class="weui-news-round" /><span class="weui-news-left">{{ item.playnum
                }} رەت كورىلدى</span>
              </div>
              <div class="weui-news-infoitem">{{ item.time }}</div>
            </div>
          </div>
          <div class="weui-news-media">
            <img class="coverimg" :src="item.img" />
          </div>
        </div>
      </li>
    </ul>


    <img src="../assets/banner.jpeg" class="img-max" style="border-radius: 10px;" />
    <h4 style="text-align:justify">بۇركىت دەتالىن تۇسرىپ الۋان تۇرلى مازمۇنداردى تاماشالاپ كەرەمەت يقۋاتتاردى
      پايدالانڭىز</h4>


  </div>
</template>
 
<script>
import { getHome } from "../api";



export default {
  name: "contianer",
  data() {
    return {
      dianyinglist: [],
      dianshijulist: [],
      xinwenlist: [],
      gedanlist: [],
    };
  },
  methods: {
    dianyingClick: function (index) {
      this.$router.push({
        path: "/VideoPlayer",
        query: { id: this.dianyinglist[index].id },
      });
    },
    dianshijuClick: function (index) {
      this.$router.push({
        path: "/VideoPlayer",
        query: { id: this.dianshijulist[index].id },
      });
    },
    gedanClick: function (index) {
      this.$router.push({
        path: "/MusicPlayer",
        query: { id: this.gedanlist[index].id },
      });
    },
    xinwenClick: function (index) {
      this.$router.push({
        path: "/NewsDetail",
        query: { id: this.xinwenlist[index].id },
      });

    },

    downloadClick: function () {

      alert("ۇيشىننڭ ىشنەن باسقا دەتالداردى تۇسرۋگە بولمايتىن سەبەپتى وڭ جاق ۇستندەگى ۇشن نۇكتەنى باسپ 在浏览器打开دەگەندى باسپ تۇسىرىڭىز", function () {
        // return alert('回调');
        return false;
      });

    },
  },
  created: function () {
    var that = this;

    getHome().then(function (ret) {
      // console.log(ret.data);

      that.$nextTick(() => {
        that.dianyinglist = ret.data["dianying"];
        that.dianshijulist = ret.data["dianshiju"];
        that.xinwenlist = ret.data["xinwen"];
        that.gedanlist = ret.data["gedan"];
      });
    });
  },
};
</script>

<style>
.logo {
  width: 48px;
  height: 48px;
}

.logobox {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: left;
}

.appname {
  margin-left: 8px;
  margin-top: 8px;
  font-size: 20px;
  font-weight: bold;
  color: black;

}

.img-max {
  margin-top: 10px;
}

.title {
  width: 100%;
  clear: both;
}

.name {
  white-space: nowrap;
  /*强制span不换行*/
  display: inline-block;
  /*限制宽度*/
  overflow: hidden;
  /*超出宽度部分隐藏*/
  text-overflow: ellipsis;
  /*超出部分以点号代替*/
  line-height: 0.9;
  /*数字与之前的文字对齐*/
}

.coverimg {

  border-radius: 10px;

}

#videoimg {
  height: 40px;
}

</style>