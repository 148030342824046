<template>
    <div class="container page-hd">


        <div class="box v-center"> <img class="logo" src="../assets/logo.jpg" style="clear: both;" />
        </div>

        <div class="box v-center"><span class="appname" style="clear: both;color: white;">Burket</span></div>

       <div class="box v-center"> <img class="banner1" src="../assets/phone.svg" style="clear: both;" />
        </div>

        <div class="box v-center"> <a href="https://www.burket.cn/burket.apk" class="weui-btn bg-green"
                style="clear: both;">تەلەفون نۇسقاسىن تۇسىرۋ</a>
        </div>

        <div class="box v-center">
            <h5 class="appinfo">بۇركىت دەتالىنڭ تەلەۆيزور نۇسقاسىن تەلەۆيزورعا ورناتۋدى تۇسىنبەسەڭىز،تەلەفون نۇسقاسىنڭ بىزگە حابارلاسۋدى باسىپ بىزگە حابارلاسىڭىز</h5>
        </div>

        <div class="box v-center"> <img class="banner2" src="../assets/tv.svg" style="clear: both;" />
        </div>

         <div class="box v-center"> <a href="https://www.burket.cn/burkettv.apk" class="weui-btn bg-green"
                style="clear: both;">جاڭا تەلەۆيزور </a>
        </div>

        <div class="box v-center"> <a href="https://www.burket.cn/burkettvNDK.apk" class="weui-btn bg-green"
                style="clear: both;">كونە تەلەۆيزور </a>
        </div>

        <div class="box v-center"> <a href="https://www.burket.cn/burkettvHAIER.apk" class="weui-btn bg-green"
                style="clear: both;">海尔闪退专用</a>
        </div>

        <div class="box v-center"> <img class="banner3" src="../assets/windows.svg" style="clear: both;" />
        </div>

         <div class="box v-center"> <a href="https://www.burket.cn/burket.exe" class="weui-btn bg-green"
                style="clear: both;">windowsكوميوتەر نۇسقاسى</a>
        </div>

        <div class="box v-center"> <img class="banner4" src="../assets/mac.svg" style="clear: both;" />
        </div>

         <div class="box v-center"> <a href="https://www.burket.cn/Burket.dmg" class="weui-btn bg-green"
                style="clear: both;">macكوميوتەر نۇسقاسى</a>
        </div>

        <div class="box v-center"> <img class="banner5" src="../assets/gongzhonghao.jpg" style="clear: both;" />
        </div>
        <div class="box v-center">
            <h5 class="appinfo">ەكىلىكتى جايمالاپ بۇركىت دەتالىنا قوسىلىپ الىڭىز دەتال جايلى جاڭالىقتاردى جولداپ وتىرامىز</h5>
        </div>

        <div class="box v-center">
            <h5 class="appinfo">بۇركىت دەتالىن تۇسرىپ سان الۋان مازمۇندارمەن يقۋاتتاردى پايدالانڭىز</h5>
        </div>

        <div class="weui-footer weui-footer_fixed-bottom">
            <p class="weui-footer__text">Copyright &copy; Burket</p>
        </div>

    </div>
</template>

<script>
export default {};
</script>

<style>
.box {
    width: 100%;
    clear: both;
    margin-top: 20px;
}

.container {

padding-top: 100px;
background-image:url(../assets/playbg.png);

}

.h1 {
color: white;
}


.appname {
color: white;
}

.appinfo {
color: white;
}

.banner5 {
    height: 150px;
    width: 150px;
}




</style>