<template>
  <div class="contianer page-hd">


    <div class="weui-flex">
      <div class="weui-flex__item">
        <a class="weui-btn bg-orange" v-on:click="downloadClick()">دەتالدى تۇسىرۋ</a>
      </div>
      <div class="weui-flex__item">
        <div class="logobox" v-on:click="homeClick()">
          <span class="appname">باس بەت</span>
          <img class="logo" src="../assets/logo.jpg" />
        </div>
      </div>
    </div>


    <div id="app">
      <div class="player-container">
        <div class="player-container">
          <vue-core-video-player :src="videoSource" :cover="cover" :title="title" autoplay />
        </div>
      </div>
    </div>

    <h4 class="title">{{ title }}</h4>
    <p class="page-hd-desc">{{ playnum }} ىرەت كورىلدى</p>
    <ul class="numberlist">
      <li class="videonum" v-for="(item, index) in videolist" :key="index" v-on:click="numClick(index)">
        {{ index + 1 }}
      </li>
    </ul>

    <div class="article">

      <img style="border-radius: 10px;" class="avator" v-if="avator.indexOf('http') != -1" :src="avator" />
      <img style="border-radius: 10px;" class="avator" v-if="avator.indexOf('http') == -1"
        :src="'https://app.burket.cn/upload/' + avator" />
      <p class="remark">{{ article }}</p>

    </div>


    <img src="../assets/banner.jpeg" class="img-max" style="border-radius: 10px;" />
    <h4 style="text-align:justify">بۇركىت دەتالىن تۇسرىپ الۋان تۇرلى مازمۇنداردى تاماشالاپ كەرەمەت يقۋاتتاردى پايدالانڭىز
    </h4>




  </div>
</template>

<script>
import { getAll, getRedictVideoUrl } from "../api";

export default {
  name: "app",
  data() {
    return {
      videoSource: [
        {
          src: "https://app.burket.cn/api/Index/wxv?id=wxv_2379200322062876672",
        },
      ],
      cover: "",
      title: "",
      article: "",
      playnum: "",
      avator: "",
      videolist: [],
    };
  },
  methods: {

    downloadClick: function () {

      alert("ۇيشىننڭ ىشنەن باسقا دەتالداردى تۇسرۋگە بولمايتىن سەبەپتى وڭ جاق ۇستندەگى ۇشن نۇكتەنى باسپ 在浏览器打开دەگەندى باسپ تۇسىرىڭىز", function () {
        // return alert('回调');
        return false;
      });

    },
    homeClick: function () {
      this.$router.push({
        path: "/",
      });

    },

    numClick: function (index) {

      var that = this;

      if (this.videolist[index].vip != 0) {


        this.title = "《 " + this.videolist[index].name + " 》" + "بۇل سەرياسىن بۇركىت دەتالىن تۇسىرگەندە قانا كورە الاسىز";

        getRedictVideoUrl({ id: 'wxv_2379200322062876672' }).then(function (res) {

          // console.log("重定向之后的路径:",res.data);
          that.videoSource = res.data;

        });

      } else {


        this.title = "《 " + this.videolist[index].name + " 》";


        const url = new URL(this.videolist[index].url);
        const searchParams = new URLSearchParams(url.search);
        const urlid = searchParams.get('id');
        getRedictVideoUrl({ id: urlid }).then(function (res) {

          console.log("重定向之后的路径:", res.data);
          that.videoSource = res.data;

        });

      }
    },




  },
  created: function () {
    var that = this;

    const vid = this.$route.query.id;

    getAll({ id: vid }).then(function (ret) {
      console.log(ret.data);
      that.cover = ret.data.info.img;
      that.article = ret.data.info.remark;
      that.playnum = ret.data.info.playnum;
      that.avator = ret.data.info.img;


      that.videolist = ret.data.list;

      if (ret.data.list[0].vip != 0) {


        that.title = "《 " + ret.data.list[0].name + " 》" + "بۇل سەرياسىن بۇركىت دەتالىن تۇسىرگەندە قانا كورە الاسىز";


        getRedictVideoUrl({ id: 'wxv_2379200322062876672' }).then(function (res) {

          // console.log("重定向之后的路径:",res.data);
          that.videoSource = res.data;

        });

      } else {

        that.title = "《 " + ret.data.list[0].name + " 》";


        const url = new URL(ret.data.list[0].url);
        const searchParams = new URLSearchParams(url.search);
        const urlid = searchParams.get('id');
        getRedictVideoUrl({ id: urlid }).then(function (res) {

          // console.log("重定向之后的路径:",res.data);
          that.videoSource = res.data;

        });

      }


    });


  },
};
</script>

<style>
.title {
  margin-top: 20px;
}

.numberlist {
  margin-top: 15px;
}

.article {
  padding-top: 15px;
  clear: both;
  text-align: justify;
}


.avator {
  float: left;
  margin-right: 10px;
  width: 120px;
  height: 150px;
}

#app {
  margin-top: 20px;
}

.videonum {

  text-align: center;
  border-radius: 5px;
  background: #f2f2f2;
  width: 20px;
  height: 20px;
  margin: 5px;
  padding: 15px;
  list-style: none;
  float: left;

}

.logobox {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: left;
}

.appname {
  margin-left: 8px;
  margin-top: 8px;
  font-size: 20px;
  font-weight: bold;
  color: black;

}
</style>